import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'reactstrap';
import { ApplicationState } from '../store';
import { requestHeaderDetails } from '../store/actions/sendLinkActions';
import Header from "./Layout/Header";
import Footer from "./Layout/Footer";
import { addWalkMeScript } from "../common/WalkMe/WalkMe";
import { DropOffConstants } from '../helper/Constants';
import { DropOffType } from '../core/common/enums';
import { checkIfUrlMatchesAnyRoute } from '../helper/HelperFunctions';
import {injectPendoScript} from "../components/common/Pendo";

declare global {
    interface Window {
        pendo: any;
    }
}

const Layout: React.FC = (props: any) => {

    const dispatch = useDispatch();
    const clientId = props.match.params.clientId || props.match.params.id;
    const encodedEmail = props.match.params.encodedEmail;

    const userInfo = useSelector((appState: ApplicationState) => appState.userState);
    const headerInfo = useSelector((appState: ApplicationState) => appState.sendLink.headerInfo);
    const [isDropOff, setIsDropOff] = React.useState<boolean>(false);

    useEffect(() => {
        const isFolderDropOffUrl = checkIfUrlMatchesAnyRoute(props.match.url, DropOffConstants.FOLDER_DROP_OFF);
        const isSendLinkUrl = (checkIfUrlMatchesAnyRoute(props.match.url, DropOffConstants.SEND_LINK) || checkIfUrlMatchesAnyRoute(props.match.url, DropOffConstants.DOCUMENT_REQUEST));
        const folderDropOffType = isFolderDropOffUrl ? DropOffType.FolderDropOff : DropOffType.DropOff;
        const dropOffType = isSendLinkUrl ? DropOffType.SendLink : folderDropOffType;
        addWalkMeScript(clientId, folderDropOffType);
        dispatch(requestHeaderDetails(clientId, dropOffType));
        setIsDropOff(isDropOff);
    }, []);

    useEffect(()=>{
        if(userInfo?.emailId){
            injectPendoScript(headerInfo.companyId,headerInfo.companyName,userInfo.emailId,headerInfo.isTestCompany);
        }
    }, [userInfo]);


    return (
        <>
            <Header clientId={clientId} headerInfo={headerInfo} encodedEmail={encodedEmail} />
            {props.customClass ? <div className={props.customClass}>
                {props.children}
            </div> : <Container className={isDropOff ? "drop-off" : ""}>
                {props.children}
            </Container>}
            <Footer />
        </>
    );
};

Layout.displayName = Layout.name;
export default Layout;